import React, {useState} from "react"
import {observer} from "mobx-react"
import InstallationSummaryStore from "src/views/InstallationSummary/stores/InstallationSummaryStore"

export const InstallationContext = React.createContext({})

const WithInstallationStore = observer(({children}) => {
    const [installationStore] = useState(new InstallationSummaryStore())

    return (
        <InstallationContext.Provider value={installationStore}>
            {children}
        </InstallationContext.Provider>
    )
})

export function withInstallationStore(Component) {
    return function withInstallationStoreComponent(props) {
        return (
            <WithInstallationStore>
                <Component {...props}/>
            </WithInstallationStore>
        )
    }
}