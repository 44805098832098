import FormStore from "../../../utils/stores/FormStore"
import {makeObservable, observable} from "mobx"
import moment from "moment"

const initState = {
    from: null,
    to: null,
    Categories: [],
    Territory: "",
    Locality: "",
    ReasonId: "",
    DealersId: [],
}

const validateData = {
    territory: {required: true}
}

class InstallationFilterStore extends FormStore {
    constructor() {
        super({initState, validateData})
        this.dateNames = [["from", "to"]]
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default InstallationFilterStore