import React, { useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import { observer } from 'mobx-react'
import { InstallationContext } from '../context/withInstallationSummary'
import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'
import { getComp } from '../../../utils/utils/DI'
import { Portal } from '@mui/material'
import { BrandingWatermark } from '@mui/icons-material'

const TableCell = observer(({ params, propName, textStart, sx, data }) => {
    const [isHovered, setIsHovered] = useState(false)
    const statusStore = getComp('StatusesStore')

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const getDataTooltip = (el) => {
        switch (propName) {
            case 'totalReported':
                return el.status === 15 && el.isDocumented
                break
            case 'totalClosed':
                return el.status === 16 && el.isDocumented
                break

            case 'otherTotal':
                return !el.isDocumented
                break
            case 'total':
                return true
                break
        }
    }

    const getTitleTooltip = () => {
        switch (propName) {
            case 'totalReported':
                return 'Заявок с проверенными данными'

            case 'totalClosed':
                return 'Заявок в закрытых отчетах'

            case 'otherTotal':
                return 'Заявок в процессе проверки'

            case 'total':
                return 'Всего заявок по территории'
        }
    }

    const ifColumns = ['totalClosed', 'totalReported', 'otherTotal', 'total'].includes(propName)

    return (
        <Box
            width={1}
            className={`aln-center ${textStart ? 'jst-start' : 'jst-center'}`}
            // sx={{ position: 'relative' }}
        >
            <Typography
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    ...sx,
                    position: 'relative',
                    cursor: ifColumns && !!params[propName]  && params.unitedTerritoryName !== 'ИТОГО (по всем записям)'  ? 'pointer' : 'auto',
                    // color: ifColumns && !!params[propName] ? 'rgba(22, 104, 185, 1)' : 'rgba(0, 0, 0, 1)',
                    // '&:hover': {
                    //     color: ifColumns && !!params[propName] && '#ea2020',
                    // },
                }}
                variant={'body1'}
                
            >
                {params[propName]}
            </Typography>
            {isHovered && ifColumns && !!params[propName] && params.unitedTerritoryName !== 'ИТОГО (по всем записям)' && (
                <Box
                    sx={{
                        background: '#fff',
                        position: 'absolute',
                        left: '50%',
                        bottom: '40px',
                        transform: 'translateX(-50%)',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        padding: '8px',
                        zIndex: 1002,
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16.8px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16.8px',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                        }}
                    >
                        {getTitleTooltip()}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            // flexWrap: 'wrap',
                        }}
                    >
                        {data
                            ?.filter(
                                (el) =>
                                    getDataTooltip(el) &&
                                    (el.name === params.unitedTerritoryName )
                            )
                            .reduce((acc, el) => {
                                const existingEl = acc.find((item) => item.status === el.status)
                                if (existingEl) {
                                    existingEl.total += el.total
                                } else {
                                    acc.push({ ...el })
                                }
                                return acc
                            }, [])
                            .map((el) => {
                                return (
                                    <Box
                                        key={el.status}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <img
                                            src={statusStore.getStatusImage(el.status)}
                                            alt=''
                                            style={{ width: '16px', height: '16px' }}
                                        />
                                        <Typography>{el.total}</Typography>
                                    </Box>
                                )
                            })}
                    </Box>
                </Box>
            )}
        </Box>
    )
})

const Header = ({ title, data, keywords }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '4px' }}>
            <Typography sx={{ fontWeight: '500', fontSize: '14px' }} variant={'body1'}>
                {title}
            </Typography>
            {true && (
                <TooltipButton
                    content={<TooltipInfoContent title={title} data={data} keywords={keywords} />}
                    title={title}
                    titleIcon={'blue'}
                    placement={'bottom'}
                />
            )}
        </Box>
    )
}

const InstallationTable = () => {
    const { collection, data1 } = useContext(InstallationContext)

    const intl = useIntl()
    const columns = useMemo(() => {
        return [
            {
                propName: 'unitedTerritoryName',
                headerName: intl.formatMessage({ id: 'Регион / МО или ГО' }),
                renderCell: (params) => (
                    <TableCell
                        key={params.unitedTerritoryName}
                        params={params}
                        propName={'unitedTerritoryName'}
                        textStart={true}
                        sx={{ fontWeight: '500', fontSize: '14px' }}
                    />
                ),
            },
            {
                propName: 'otherTotal',
                headerName: (
                    <Header
                        title={intl.formatMessage({ id: 'Установок в процессе проверки' })}
                        data={[
                            'Счётчик показывает количество выполненных установок по региональным округам по заявкам не добавленным в отчёт в статусе “Установлено”, а также по добавленным в отчёт со статусами отчётов “Создан”, “Проверка данных”, “Уточнение данных”',
                        ]}
                        keywords={['Установлено', 'Создан', 'Проверка данных', 'Уточнение данных']}
                    />
                ),
                renderCell: (params) => (
                    <TableCell
                        data={data1}
                        key={params.otherTotal + params.unitedTerritoryName}
                        params={params}
                        propName={'otherTotal'}
                    />
                ),
                headerStart: true,
            },
            {
                propName: 'totalReported',
                headerName: (
                    <Header
                        title={intl.formatMessage({ id: 'Установок с проверенными данными' })}
                        data={[
                            'Счётчик показывает количество выполненных установок по региональным округам с проверенными данными, которые находятся в отчётах со статусами “Проверка завершена”, “Отправлен”, “Получен”, “Проверка отчёта”, “На оплату”',
                        ]}
                        keywords={['Проверка завершена', 'Отправлен', 'Получен', 'Проверка отчёта', 'На оплату']}
                    />
                ),
                renderCell: (params) => (
                    <TableCell
                        data={data1}
                        key={params.totalReported + params.unitedTerritoryName}
                        params={params}
                        propName={'totalReported'}
                    />
                ),
            },
            {
                propName: 'totalClosed',
                headerName: (
                    <Header
                        title={intl.formatMessage({ id: 'Установок в закрытых отчётах' })}
                        data={[
                            'Счётчик показывает количество выполненных установок по региональным округам с проверенными данными, которые находятся в отчётах в статусе “Закрыт”',
                        ]}
                        keywords={['Закрыт']}
                    />
                ),
                renderCell: (params) => (
                    <TableCell
                        data={data1}
                        key={params.totalClosed + params.unitedTerritoryName}
                        params={params}
                        propName={'totalClosed'}
                    />
                ),
            },

            {
                propName: 'total',
                headerName: (
                    <Header
                        title={intl.formatMessage({ id: 'Всего установок по территории' })}
                        data={['Счётчик показывает суммарное количество установок по региону']}
                        keywords={[]}
                    />
                ),
                renderCell: (params) => (
                    <TableCell
                        data={data1}
                        key={params.total + params.unitedTerritoryName}
                        params={params}
                        propName={'total'}
                    />
                ),
            },
        ]
    }, [intl, data1])

    return (
        <CustomTable
            containerStyle={{ maxHeight: 'calc(62vh)' }}
            showPagination={false}
            columns={columns}
            rows={collection}
            total={collection.length}
            noContentText={
                <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                    {intl.formatMessage({ id: 'выберите параметры для отображения данных' })}
                </Typography>
            }
        />
    )
}

export default observer(InstallationTable)
