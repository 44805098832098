import {makeAutoObservable} from "mobx"
import {getComp} from "../../../utils/utils/DI"
import InstallationFilterStore from "./InstallationFilterStore"
import {getInstallationSummary} from "../../../services/ApiService"
import moment from "moment/moment"
import {gState} from "../../../utils/controllers/GlobalStateController"
import { closeModalFn } from "../../../utils/controllers/ModalController"

class InstallationSummaryStore {
    data1 = []
    constructor() {
        this.territoryStore = getComp("TerritoryStore")
        this.categoriesStore = getComp("CategoriesStore")
        this.dealersStore = getComp("DealersStore")
        this.statusesStore = getComp("StatusesStore")
        this.reasonStore = getComp("InstallationReasonStore")
        this.filterStore = new InstallationFilterStore()
        this.collection = []
      
        makeAutoObservable(this)
    }

    loadFile = () => {
        const intl = gState["intl"]
        const fields = [
            [
                intl.formatMessage({id: "Регион / МО или ГО"}),
                intl.formatMessage({id: "Установок в процессе проверки"}),
                intl.formatMessage({id: "Установок с проверенными данными"}),
                intl.formatMessage({id: "Установок в закрытых отчётах"}),
                intl.formatMessage({id: "Всего установок по территории"}),
            ],
        ]
        const sortedKeys = ["unitedTerritoryName", "otherTotal", "totalReported", "totalClosed",  "total"]

        this.collection.forEach((row) => {
            fields.push(
                sortedKeys.map((key) => {
                    if (row[key] !== undefined) {
                        return row[key]
                    }
                    return "-"
                })
            )
        })

        const blobData = fields.map((el) => el.join(window.SAT.config.csvParams.divider)).join("\n")
        const data = new TextEncoder("utf-16be").encode(blobData)
        const blob = new File(["\uFEFF", data], intl.formatMessage({id: "Заявки"}), {
            type: `text/csv;charset=utf-8`,
        })
        window.location = window.URL.createObjectURL(blob)
    }

    _groupByName = (statisticResult) => {
        return statisticResult.reduce((row, item) => {
            const {name, total, status, isDocumented} = item
            if (!row[name]) {
                row[name] = {
                    totalReported: 0,
                    totalClosed: 0,
                    otherTotal: 0,
                }
            }
            //row[name] = {
            //      totalReported: 15
            //      totalClosed: 16
            //      otherTotal: other
            // }

            row[name].totalReported += status === 15 && isDocumented ? total : 0
            row[name].totalClosed += status === 16 && isDocumented ? total : 0
            row[name].otherTotal += !isDocumented ? total : 0
            row[name].total = row[name].totalReported + row[name].totalClosed + row[name].otherTotal

            return row
        }, {})
    }

    getStatistic = () => {
        const requestParam = {}
        for (const key in this.filterStore.data) {
            if (
                this.filterStore.data[key] !== "" &&
                this.filterStore.data[key] !== null &&
                this.filterStore.data[key] !== "Invalid date"
            ) {
                requestParam[key] = this.filterStore.data[key]
                if (key === "from" || key === "to") {
                    requestParam[key] = moment(this.filterStore.data[key]).local(true).format()
                }
            }
        }
        return getInstallationSummary(requestParam).then((res) => {
            this.data1 = res
            this.filterStore.setSelectedFilters()
            const groupedByName = this._groupByName(res)
            const arrayFromGrouped = Object.entries(groupedByName).map(([key, value]) => {
                return {unitedTerritoryName: key, ...value}
            })

            // const totalResultRow = {
            //     totalReported: 0,
            //     totalClosed: 0,
            //     otherTotal: 0,
            //     unitedTerritoryName: gState['intl'].formatMessage({id: 'ИТОГО (по всем записям)'}),
            //     totalLast: true
            // }

            const totalResultRow = arrayFromGrouped.reduce(
                (acc, item) => {
                    acc.totalReported += item.totalReported
                    acc.totalClosed += item.totalClosed
                    acc.otherTotal += item.otherTotal
                    acc.total += item.total
                    return acc
                },
                {
                    totalReported: 0,
                    totalClosed: 0,
                    otherTotal: 0,
                    total: 0,
                    unitedTerritoryName: gState["intl"].formatMessage({id: "ИТОГО (по всем записям)"}),
                    totalLast: true,
                }
            )

            arrayFromGrouped.push(totalResultRow)
            this.collection = arrayFromGrouped
            closeModalFn['progress-backdrop']()
        })
    }
}

export default InstallationSummaryStore