import React from "react"
import {observer} from "mobx-react"
import {withInstallationStore} from "./context/withInstallationSummary"
import Box from "@mui/material/Box"
import InstallationFilters from "./components/InstallationFilters"
import InstallationTable from "./components/InstallationTable"

const InstallationSummary = () => {
    return (
        <Box width={1}>
            <Box mb={2}>
                <InstallationFilters/>
            </Box>
            <InstallationTable/>
        </Box>
    )
}

export default withInstallationStore(observer(InstallationSummary))
