import React, {useContext} from "react"
import {observer} from "mobx-react"
import {useIntl} from "react-intl"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment"
import {InstallationContext} from "../context/withInstallationSummary"
import NewFilters from "../../../components/NewFilters/NewFilters"
import InstallationSummaryFilterChip from "./InstallationSummaryFilterChip"
import downloadWhite from "../../../assets/images/common/downloadWhite.svg"
import downloadGray from "../../../assets/images/common/downloadGray.svg"

const InstallationFilters = () => {
    const {
        dealersStore,
        categoriesStore,
        territoryStore,
        reasonStore,
        filterStore,
        getStatistic,
        loadFile,
        collection
    } = useContext(InstallationContext)
    const intl = useIntl()

    const reasons = reasonStore.reasons.map((reason) => {
        if (reason.key === "Замена") {
            reason.key = reason.key + " (Горыныч)"
        }
        return reason
    })

    const filtersData = [
        [
            {
                type: "select",
                propName: "Territory",
                name: intl.formatMessage({id: "Регион"}),
                collection: territoryStore.collection
            },
            [
                {
                    type: "date",
                    propName: "from",
                    name: intl.formatMessage({id: "Дата начала периода"}),
                    minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                    maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                },
                {
                    type: "date",
                    propName: "to",
                    name: intl.formatMessage({id: "Дата окончания периода"}),
                    minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                    maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                }
            ],
            {
                type: "select",
                multiple: true,
                propName: "Categories",
                collection: categoriesStore.collection.map((category) => {
                    return {value: category.id, key: category.name}
                }),
                setMenuItem: (el) => {
                    return <MenuItem value={el.value}>{el.key}</MenuItem>
                },
                name: intl.formatMessage({id: "Льготные категории"})
            },
            {
                type: "select",
                propName: "ReasonId",
                collection: reasons,
                name: intl.formatMessage({id: "Причина"}),
                setMenuItem: (el) => {
                    return <MenuItem value={el.value}>{el.key}</MenuItem>
                }
            },
            {
                type: "select",
                multiple: true,
                name: intl.formatMessage({id: "Дилер"}),
                propName: "DealersId",
                collection: dealersStore.collection.map((dealer) => {
                    return {value: dealer.subjectId, key: dealer.displayName}
                }),
                setMenuItem: (el) => {
                    return <MenuItem value={el.value}>{el.key}</MenuItem>
                }
            }
        ]
    ]

    const handleDeleteChip = (propName, multiselectValue) => {
        filterStore.deleteFilter(propName, multiselectValue)
        getStatistic()
        return Promise.resolve()
    }

    const setChip = (params, withAction) => (
        <InstallationSummaryFilterChip {...params} onClick={withAction && handleDeleteChip} />
    )

    return (
        <NewFilters
            title={intl.formatMessage({id: "Результаты поиска"})}
            filtersStore={filterStore}
            onSearch={getStatistic}
            filtersData={filtersData}
            setChip={setChip}
            actionButtonProps={{
                name: intl.formatMessage({id: "Сохранить в файл"}),
                disabled: !collection.length,
                onClick: loadFile,
                icon: collection.length ? downloadWhite : downloadGray
            }}
        />
    )
}

export default observer(InstallationFilters)
